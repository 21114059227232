import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { SocketContext } from '../Context';
import Audio from './Audio';

const Notifications = () => {
  const { responderChamada, chamada, chamadaAceita } =
    React.useContext(SocketContext);
  return (
    <>
      {typeof chamada.isReceivingCall != 'undefined' &&
        chamada.isReceivingCall &&
        !chamadaAceita && <Audio></Audio>}
      <Dialog
        open={
          typeof chamada.isReceivingCall != 'undefined' &&
          chamada.isReceivingCall &&
          !chamadaAceita
        }
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Chamando
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {chamada.nome} está chamando você...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={responderChamada}
          >
            Aceitar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Notifications;
