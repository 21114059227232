import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import meuAUdio from '../efeito_sonoro.mp3';

const Audio = () => {
  return (
    <ReactAudioPlayer
      src={meuAUdio}
      autoPlay
      controls
      loop
      style={{ display: 'none' }}
    />
  );
};

export default Audio;
