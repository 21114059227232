import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Pagina404 from './Pagina404';
import { ContextProvider } from './Context';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './styles.css';

ReactDOM.render(
  <BrowserRouter>
    <ContextProvider>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="virtualmenteadmin" element={<App />}></Route>
        <Route path="*" element={<Pagina404 />}></Route>
      </Routes>
    </ContextProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
