import React from 'react';
import { Typography, AppBar, Chip, Snackbar } from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@material-ui/core/styles';

import VideoPlayer from './components/VideoPlayer';
import Sidebar from './components/Sidebar';
import Notifications from './components/Notifications';
import { SocketContext } from './Context';
import logo from './imagens/logo.jpg';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    border: '1px solid #cdcdcd',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  appBarMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  chipChamada: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    marginLeft: '15px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const App = () => {
  const classes = useStyles();
  const {
    usuarios,
    chamadaAceita,
    chamadaEncerrada,
    chamadaEncerradaExt,
    setChamadaEncerradaExt,
  } = React.useContext(SocketContext);
  return (
    <div className={classes.wrapper}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={chamadaEncerradaExt}
        autoHideDuration={6000}
        onClose={() => setChamadaEncerradaExt(false)}
      >
        <Alert
          onClose={() => setChamadaEncerradaExt(false)}
          severity="info"
          sx={{ width: '100%' }}
        >
          Chamada encerrada externamente
        </Alert>
      </Snackbar>
      {usuarios.map(({ id }) => (
        <Chip
          key={id}
          className={classes.chipChamada}
          label={'Usuário entrou, chamar pelo ID: ' + id}
        />
      ))}
      <AppBar
        className={
          chamadaAceita && !chamadaEncerrada
            ? classes.appBar + ' ' + classes.appBarMobile
            : classes.appBar
        }
        position="static"
        color="inherit"
      >
        <img src={logo} height="200px;"></img>
      </AppBar>
      <VideoPlayer />
      <Sidebar>
        <Notifications />
      </Sidebar>
    </div>
  );
};

export default App;
