import React from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import { useLocation } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import useFetch from './customHooks/useFetch';
import foto from './imagens/fundo_sem_cam.jpg';
import Pagina404 from './Pagina404';

const SocketContext = React.createContext();

const ContextProvider = ({ children }) => {
  const [token, setToken] = React.useState(() => {
    if (localStorage.getItem('token')) return localStorage.getItem('token');
    // 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTc2MjU1MTE0MiwiaWF0IjoxNjY3ODU2NzQyfQ.Zl1W-g9pwAh1lyqJfqvCRDPtajpkK0iH8up95vGDC5s'
  });
  const { decodedToken, isExpired } = useJwt(token);
  const [chamadaAceita, setChamadaAceita] = React.useState(false);
  const [chamadaEncerrada, setChamadaEncerrada] = React.useState(false);
  const [stream, setStream] = React.useState();
  const [nome, setNome] = React.useState('');
  const [sala, setSala] = React.useState('');
  const [chamada, setChamada] = React.useState({});
  const [usuarios, setUsuarios] = React.useState([]);
  const [erroMidea, setErroMidea] = React.useState(null);
  const [me, setMe] = React.useState('');
  const [audioRemovido, setAudioRemovido] = React.useState(false);
  const [videoDesabilitado, setVideoDesabilitado] = React.useState(false);
  const [chamadaEncerradaExt, setChamadaEncerradaExt] = React.useState(false);
  const [chamando, setChamando] = React.useState(false);
  const [erroGeral, setErroGeral] = React.useState('');
  const [width, setWidth] = React.useState(window.innerWidth);

  const location = useLocation();
  const queryString = new URLSearchParams(location.search);

  const meuVideo = React.useRef();
  const VideoExterno = React.useRef();
  const peerRef = React.useRef();
  const tipo = React.useRef();
  const socket = React.useRef();
  const trackBKP = React.useRef();
  const { data, loading, error, request } = useFetch();

  const finalizaSocket = React.useCallback(() => {
    if (socket.current) {
      socket.current.disconnect();
      socket.current.close();
    }
  }, []);

  const reload = React.useCallback(() => {
    socket.current.emit('reload');
  }, []);
  const inicializaSocketVideo = React.useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((currentStream) => {
        setStream(currentStream);
        meuVideo.current.srcObject = currentStream;
        inicializaSocket();
      })
      .catch((err) => {
        setErroMidea(err);
        setErroGeral('Favor habilitar câmera e microfone');
      });
  }, []);
  const inicializaSocket = React.useCallback((callback) => {
    /*socket.current = io('http://localhost:5000/', {
      query: {
        sala: queryString.get('sala'),
      },
    });*/
    socket.current = io('https://api.curitibaemboaforma.com.br/', {
      query: {
        sala: queryString.get('sala'),
      },
    });

    /*const socket = io('https://api.curitibaemboaforma.com.br/', {
  transports: ['websocket'],
});*/

    socket.current.on('connect', function () {
      console.log('conectado por socket!');
    });

    socket.current.on('usuarioIdentificado', (id) => {
      if (tipo.current == 1) {
        setUsuarios([...usuarios, id]);
      }
    });
    socket.current.on('reload', () => {
      window.location.reload();
    });
    socket.current.on('encerrarChamada', () => {
      encerrarChamada();
      setChamadaEncerradaExt(true);
    });
    socket.current.on('chamar', ({ idOrigem, nome: callerName, signal }) => {
      setChamada({
        isReceivingCall: true,
        idOrigem,
        nome: callerName,
        signal,
      });
    });
    socket.current.on('identificar', (id) => {
      if (tipo.current == 0) {
        socket.current.emit('identificarUsuario', {
          id,
        });
      }
      setMe(id);
      socket.current.emit('verSalas', function (salas) {
        console.log(salas);
      });

      if (callback) callback(id);
    });
  }, []);

  React.useEffect(() => {
    tipo.current =
      window.location.pathname.indexOf('/virtualmenteadmin/') >= 0 ? 1 : 0;

    window.addEventListener('resize', handleWindowSizeChange);
    request(
      'https://api-restfull.curitibaemboaforma.com.br/api/callRoon/GetRoonByID',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          id_callroon: queryString.get('sala'),
        }),
      },
    );
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      encerrarChamada(true);
    };
  }, [request]);

  React.useEffect(() => {
    //inicializaSocketVideo();
    console.log(data);
    if (queryString.get('sala') && data) {
      if (data[0] && data[0].is_active) {
        setSala(queryString.get('sala'));
        if (tipo.current == 1) setNome(data[0].first_name);
        else setNome(data[0].username);
        inicializaSocketVideo();
      } else setErroGeral('Sala não encontrada ou finalizada');
    }
  }, [data]);

  React.useEffect(() => {
    if (decodedToken && !isExpired && decodedToken.Role == 'Admin') {
      tipo.current = 1;
    }
  }, [decodedToken]);

  React.useEffect(() => {}, [chamadaAceita]);

  const mutar = () => {
    stream.getAudioTracks()[0].enabled = false;
    setAudioRemovido(true);
  };
  const desmutar = () => {
    stream.getAudioTracks()[0].enabled = true;
    setAudioRemovido(false);
  };
  const desabilitarVideo = () => {
    //stream.getVideoTracks()[0].enabled = false;
    let canvas = document.createElement('canvas');
    console.log(meuVideo.current.width);
    let base_image = new Image();
    base_image.src = foto;
    base_image.width = 50;
    base_image.height = 50;
    canvas.getContext('2d').drawImage(base_image, 0, 0);
    let canvasStream = canvas.captureStream();
    trackBKP.current = stream.getVideoTracks()[0];
    if (peerRef.current) {
      peerRef.current.replaceTrack(
        stream.getVideoTracks()[0],
        canvasStream.getVideoTracks()[0],
        stream,
      );
    }
    let outputTracks = [];
    outputTracks = outputTracks.concat(canvasStream.getVideoTracks());
    outputTracks = outputTracks.concat(stream.getAudioTracks());
    meuVideo.current.srcObject = new MediaStream(outputTracks);
    setVideoDesabilitado(true);
  };
  const habilitarVideo = () => {
    //stream.getVideoTracks()[0].enabled = true;
    meuVideo.current.srcObject = stream;
    if (peerRef.current)
      peerRef.current.replaceTrack(
        stream.getVideoTracks()[0],
        trackBKP.current,
        stream,
      );
    setVideoDesabilitado(false);
  };
  const responderChamada = () => {
    setChamadaAceita(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });
    if (peerRef.current) peerRef.current.destroy();
    peerRef.current = peer;
    if ((!chamadaAceita || chamadaEncerrada) && videoDesabilitado) {
      peerRef.current.replaceTrack(
        stream.getVideoTracks()[0],
        meuVideo.current.srcObject.getVideoTracks()[0],
        stream,
      );
    }
    peerRef.current.on('signal', (data) => {
      socket.current.emit('responderChamada', {
        signal: data,
        para: chamada.idOrigem,
        nome,
        idOrigem: me,
      });
    });
    peerRef.current.on('error', (err) => {
      if (chamada && typeof chamada.idOrigem != 'undefined') {
        finalizarChamada();
      } else {
        encerrarChamada();
      }
    });
    peerRef.current.on('stream', (currentStream) => {
      if (VideoExterno.current) VideoExterno.current.srcObject = currentStream;
    });

    peerRef.current.signal(chamada.signal);
  };

  const chamar = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    if (peerRef.current) peerRef.current.destroy();
    peerRef.current = peer;
    if ((!chamadaAceita || chamadaEncerrada) && videoDesabilitado) {
      peerRef.current.replaceTrack(
        stream.getVideoTracks()[0],
        meuVideo.current.srcObject.getVideoTracks()[0],
        stream,
      );
    }
    peerRef.current.on('signal', (data) => {
      setChamando(true);
      socket.current.emit('chamar', {
        idParaChamar: id,
        signalData: data,
        idOrigem: me,
        nome,
      });
    });
    peerRef.current.on('error', (err) => {
      if (chamada && typeof chamada.idOrigem != 'undefined') {
        finalizarChamada();
      } else {
        encerrarChamada();
      }
    });
    peerRef.current.on('stream', (currentStream) => {
      if (VideoExterno.current) VideoExterno.current.srcObject = currentStream;
    });

    socket.current.on('chamadaAceita', (data) => {
      setChamadaAceita(true);
      setChamando(false);

      peerRef.current.signal(data.signal);
      setChamada({ nome: data.nome, idOrigem: data.idOrigem });
    });
  };
  const encerrarChamada = (naoIniciar) => {
    meuVideo.current.removeAttribute('style');
    if (peerRef.current) {
      peerRef.current.destroy();
      peerRef.current = null;
    }
    finalizaSocket();
    //setChamadaEncerrada(true);
    setChamada({});
    setVideoDesabilitado(false);
    setAudioRemovido(false);
    setChamadaAceita(false);
    if (!naoIniciar) inicializaSocketVideo();
  };
  const finalizarChamada = () => {
    socket.current.emit('encerrarChamada', { idParaChamar: chamada.idOrigem });
    encerrarChamada();
    if (tipo.current == 1) setChamando(false);
  };
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  return (
    <SocketContext.Provider
      value={{
        chamada,
        chamadaAceita,
        meuVideo,
        VideoExterno,
        stream,
        nome,
        setNome,
        chamadaEncerrada,
        me,
        chamar,
        finalizarChamada,
        responderChamada,
        erroMidea,
        tipo,
        usuarios,
        mutar,
        desmutar,
        audioRemovido,
        videoDesabilitado,
        habilitarVideo,
        desabilitarVideo,
        setChamadaEncerradaExt,
        chamadaEncerradaExt,
        chamando,
        width,
        loading,
        erroGeral,
        sala,
        reload,
      }}
    >
      {queryString.get('sala') ? children : <Pagina404 />}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
