import React from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { SocketContext } from '../Context';
import foto from '../imagens/fundo_sem_cam.jpg';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  video: {
    width: '550px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  videoOther: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      objectFit: 'cover',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 9,
    },
  },
  videoMe: {
    [theme.breakpoints.down('xs')]: {
      width: '40vw',
      objectFit: 'cover',
      height: 'auto',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 10,
    },
  },
  gridContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  paper: {
    padding: '10px',
    border: '1px solid #cdcdcd',
    margin: '10px',
  },
}));

const VideoPlayer = () => {
  const {
    nome,
    chamadaAceita,
    meuVideo,
    VideoExterno,
    chamadaEncerrada,
    stream,
    chamada,
    audioRemovido,
    width,
    tipo,
  } = React.useContext(SocketContext);

  const classes = useStyles();
  return (
    <Grid container className={classes.gridContainer}>
      <img src={foto} style={{ display: 'none' }}></img>
      {stream && (
        <Paper className={classes.paper}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {(tipo.current == 1
                ? 'Paciente: ' + nome
                : 'Atendido por: ' + nome) || 'Sem Nome'}
            </Typography>
            <div style={{ position: 'absolute' }}>
              {audioRemovido && <VolumeOffIcon></VolumeOffIcon>}
            </div>
            <Draggable
              disabled={!(chamadaAceita && !chamadaEncerrada && width <= 599)}
            >
              <video
                playsInline
                muted
                ref={meuVideo}
                poster={foto}
                autoPlay
                className={
                  chamadaAceita && !chamadaEncerrada
                    ? classes.video + ' ' + classes.videoMe
                    : classes.video
                }
              />
            </Draggable>
          </Grid>
        </Paper>
      )}
      {chamadaAceita && !chamadaEncerrada && (
        <Paper className={classes.paper}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {chamada.nome || 'Nome'}
            </Typography>
            <video
              playsInline
              ref={VideoExterno}
              poster={foto}
              autoPlay
              className={classes.video + ' ' + classes.videoOther}
            />
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default VideoPlayer;
