import React from 'react';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Paper,
  List,
  TextareaAutosize,
  Snackbar,
} from '@material-ui/core';
import SpeedDial from '@mui/material/SpeedDial';
import ChairIcon from '@mui/icons-material/Chair';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Assignment, Phone, PhoneDisabled } from '@material-ui/icons';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { makeStyles } from '@material-ui/core/styles';
import foto from '../imagens/fundo_sem_cam.jpg';
import { SocketContext } from '../Context';
import { Alert } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    width: '600px',
    margin: '35px 0',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  margin: {
    marginTop: 20,
  },
  padding: {
    padding: 20,
  },
  paper: {
    padding: '10px 20px',
    border: '1px solid #cdcdcd',
  },
}));

const Sidebar = ({ children }) => {
  const {
    me,
    chamadaAceita,
    nome,
    setNome,
    chamadaEncerrada,
    finalizarChamada,
    chamar,
    tipo,
    usuarios,
    mutar,
    audioRemovido,
    desmutar,
    desabilitarVideo,
    videoDesabilitado,
    habilitarVideo,
    chamando,
    loading,
    erroGeral,
    sala,
    reload,
  } = React.useContext(SocketContext);
  const [msgSuccess, setMsgSuccess] = React.useState('');
  const [msgError, setMsgError] = React.useState('');
  const [obs, setObs] = React.useState('');

  const inativarSala = React.useCallback(async () => {
    try {
      let response = await fetch(
        'https://api-restfull.curitibaemboaforma.com.br/api/callRoon/InactivateRoon/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({
            id_callroon: sala,
            observation: obs,
          }),
        },
      );
      let json = await response.json();
      if(json.length==0)
      {
        setMsgSuccess('Sala finalizada com sucesso!');     
        reload();   
        setTimeout(function () {
          window.location.reload();
        },3000);
      }
      else
      {
        setMsgError(json)
      }
    } catch (erro) {
      console.log(erro);
      setMsgError('Erro ao finalizar sala')
    }
  }, [sala,obs]);

  const [idParaChamar, setIdParaChamar] = React.useState('');
  const classes = useStyles();
  React.useEffect(() => {
    if (usuarios && usuarios.length > 0) {
      setIdParaChamar(usuarios[usuarios.length - 1].id);
    }
  }, [usuarios]);

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMsgSuccess('');
  };
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMsgError('');
  };

  return (
    <>
      <Snackbar
        open={msgSuccess != ''}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          {msgSuccess}
        </Alert>
      </Snackbar>
      <Snackbar
        open={msgError != ''}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          {msgError}
        </Alert>
      </Snackbar>
      <Container className={classes.container}>
        {erroGeral ? (
          <Alert severity="error">{erroGeral}</Alert>
        ) : loading ? (
          <Alert severity="info">Verificando sala...</Alert>
        ) : (
          <Paper elevation={10} className={classes.paper}>
            <form className={classes.root} noValidate autoComplete="off">
              <Grid container className={classes.gridContainer}>
                <Grid
                  item
                  xs={12}
                  md={tipo.current == 1 ? 6 : 12}
                  className={classes.padding}
                >
                  <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                  >
                    {!audioRemovido && (
                      <SpeedDialAction
                        icon={<VolumeUpIcon fontSize="large" />}
                        tooltipTitle="Mutar"
                        className={classes.margin}
                        onClick={mutar}
                        color="secondary"
                      />
                    )}
                    {audioRemovido && (
                      <SpeedDialAction
                        icon={<VolumeOffIcon fontSize="large" />}
                        tooltipTitle="Desmutar"
                        className={classes.margin}
                        onClick={desmutar}
                        color="secondary"
                      />
                    )}
                    {!videoDesabilitado && (
                      <SpeedDialAction
                        icon={<VideocamIcon fontSize="large" />}
                        tooltipTitle="Desabilitar Video"
                        className={classes.margin}
                        onClick={desabilitarVideo}
                        color="secondary"
                      />
                    )}
                    {videoDesabilitado && (
                      <SpeedDialAction
                        icon={<VideocamOffIcon fontSize="large" />}
                        tooltipTitle="Habilitar Video"
                        className={classes.margin}
                        onClick={habilitarVideo}
                        color="secondary"
                      />
                    )}
                    {chamadaAceita && !chamadaEncerrada && (
                      <SpeedDialAction
                        icon={
                          <PhoneDisabled
                            style={{ color: 'red' }}
                            fontSize="large"
                          />
                        }
                        tooltipTitle="Encerrar chamada"
                        className={classes.margin}
                        onClick={finalizarChamada}
                        color="secondary"
                      />
                    )}
                  </SpeedDial>
                  <Typography gutterBottom variant="h6">
                    Informações da Sala
                  </Typography>
                  <TextField
                    label={
                      'Nome ' +
                      (tipo.current == 1 ? 'Paciente' : 'Profissional')
                    }
                    value={nome}
                    disabled={true}
                    onChange={(e) => setNome(e.target.value)}
                    fullWidth
                  />
                  {tipo.current == 1 && (!chamadaAceita || chamadaEncerrada) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={chamando}
                      startIcon={<Phone fontSize="large" />}
                      fullWidth
                      onClick={() => chamar(idParaChamar)}
                      className={classes.margin}
                    >
                      {chamando ? 'Chamando...' : 'Chamar'}
                    </Button>
                  ) : null}
                </Grid>
                {tipo.current == 1 ? (
                  <>
                    <Grid item xs={12} md={6} className={classes.padding}>
                      <Typography gutterBottom variant="h6">Token Paciente</Typography>
                      <TextField
                        label="ID para chamar"
                        value={idParaChamar}
                        onChange={(e) => setIdParaChamar(e.target.value)}
                        fullWidth
                      />
                      {(!chamadaAceita || chamadaEncerrada) && chamando ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<PhoneDisabled fontSize="large" />}
                          fullWidth
                          onClick={finalizarChamada}
                          className={classes.margin}
                        >
                          Cancelar chamada
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.padding}>
                      {(!chamadaAceita || chamadaEncerrada) && !chamando ? (
                        <>
                          <TextareaAutosize
                            aria-label="Informe uma observação"
                            minRows={3}
                            placeholder="Informe uma observação"
                            value={obs}
                            onChange={(e) => setObs(e.target.value)}
                            style={{ width: '100%' }}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<ChairIcon fontSize="large" />}
                            fullWidth
                            onClick={inativarSala}
                            className={classes.margin}
                          >
                            Finalizar Sala
                          </Button>
                        </>
                      ) : null}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
            {children}
          </Paper>
        )}
      </Container>
      {(!chamadaAceita || chamadaEncerrada) && (
        <Container maxWidth="xl">
          <Paper elevation={3}>
            <Grid item xs={12} md={12} className={classes.padding}>
              <Typography gutterBottom variant="h6">
                Informações Adicionais:
              </Typography>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Sem Imagem" src={foto} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Câmera Desligada"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="primary"
                        >
                          Imagem irá aparecer quando a Câmera estiver desligada!
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Opções de Video"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="primary"
                        >
                          No canto inferior direito, irá aparecer um icone com o
                          simbolo "+". Ao clicar, irá habilitar as opções de
                          desabilitar audio e câmera
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Aguarde a chamada"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="primary"
                        >
                          Ao entrar na sala, espere até que o profissional
                          inicie uma chamada com você!
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default Sidebar;
